import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        redirect: "/index",
    },
    {
        path: "/index",
        name: "index",
        component: () => import("@/views/index/index.vue"),
    },
    {
        path: "/about",
        name: "about",
        component: () => import("@/views/about-us/index.vue"),
    },
    {
        path: "/cooperate",
        name: "cooperate",
        component: () => import("@/views/cooperate/index.vue"),
    },
    {
        path: "/case",
        name: "case",
        component: () => import("@/views/case/index.vue"),
    },
    {
        path: "/caseDetail",
        name: "caseDetail",
        component: () => import("@/views/case-detail/index.vue"),
    },
    {
        path: "/news",
        name: "news",
        component: () => import("@/views/news/index.vue"),
    },
    {
        path: "/newsDetail",
        name: "newsDetail",
        component: () => import("@/views/news-detail/index.vue"),
    },
    {
        path: "/companyService",
        name: "companyService",
        component: () => import("@/views/company/service/index.vue"),
    },
    {
        path: "/networkSecurity",
        name: "networkSecurity",
        component: () => import("@/views/company/network-security/index.vue"),
    },
    {
        path: "/softwareDevelopment",
        name: "softwareDevelopment",
        component: () => import("@/views/company/software-development/index.vue"),
    },
    {
        path: "/finance",
        name: "finance",
        component: () => import("@/views/company/finance/index.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () => import("@/views/not-found/not-found.vue"),
    }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
