<template>
    <el-config-provider :locale="locale">
        <router-view></router-view>
    </el-config-provider>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default defineComponent({
    name: "App",
    components: {
        ElConfigProvider,
    },
    setup() {
        return {
            locale: zhCn,
        }
     },
});
</script>

<style>
    body{font-family: '微软雅黑'};
    @import './assets/css/common.css';
    @import url('assets/css/media2000.css') all and (max-width:9800px);
    @import url('assets/css/media1800.css') all and (max-width:1800px);
    @import url('assets/css/media1600.css') all and (max-width:1600px);
    @import url('assets/css/media1400.css') all and (max-width:1400px);
    @import url('assets/css/media1200.css') all and (max-width:1200px);
    @import url('assets/css/media992.css') all and (max-width:992px);
    @import url('assets/css/media769.css') all and (max-width:769px);
    @import url('assets/css/media576.css') all and (max-width:576px);

</style>
<style lang="scss">
    @import './assets/scss/index.scss'
</style>
