import { Module } from 'vuex'
import { INewsState } from './types'
import { IRootState } from '../types'
import { casesList } from '@/service/poject-cases/pojectCases'
import message from '@/utils/message'
const newsModule: Module<INewsState, IRootState> = {
    namespaced: true,
    state() {
        return {
            list: [],
            count: 0,
            searchTitle: ""
        }
    },
    mutations: {
        changList(state, data) {
            state.list = data.result
            state.count = data.count
        }
    },
    actions: {
        async getList({ commit }, payLoad: any) {
            const result: any = await casesList('caseStudy/list', payLoad)
            console.log(result)
            commit('changList', result.data)
        }
    }
}
export default newsModule

