import { createApp } from "vue";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'normalize.css'
import baseRequest from './service/index'
createApp(App).use(ElementPlus).use(store).use(router).mount("#app");

// baseRequest.post({
//     url:"abcv",
//     showLoading:true
// }).then(res=>{
//     console.log(2,res)
// }).catch(err=>{
//     console.log(1,err)
// })
