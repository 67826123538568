import { createStore } from "vuex";
import { IRootState } from "./types";
import login from './login/login'  //导入登录相关数据
import news from './news/news'
import pojectCases from './poject-cases/pojectCases'
import consult from './consult/consult'
import customer from './customer/customer'

const store = createStore<IRootState>({
    state: ()=>{
        return{
            name:""
        }
    },
    mutations: {},
    actions: {},
    modules: {
        login, news, pojectCases, consult, customer
    },
});

export default store
