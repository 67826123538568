import baseRequest from '../index'

export function addNewsRequest(url:string,queryInfo:any){
    console.log(queryInfo)
    return baseRequest.post({
        url:url,
        data:queryInfo
    })
}

export function SearchList(url:string,queryInfo:any){
    return baseRequest.post({
        url: url,
        showLoading:true,
        data: queryInfo
    })
}
