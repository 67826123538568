import {Module} from 'vuex'
import { ILoginState } from './types'
import { IRootState } from '../types'
/**
 *  模块化vuex需要导入module类型
 *  Module<'模块中的state类型','根store中的state的类型'>
 */
const loginModule: Module<ILoginState, IRootState> = {
    namespaced: true,
    state(){
        return{
            userInfo:{}
        }
    },
    mutations:{
        changeUserInfo(state, data) {
            state.userInfo = JSON.parse(data)
        }
    },
    actions:{
    }
}

export default loginModule
