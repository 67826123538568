// 网络请求的统一出口
import BaseRequest from './request/request'
import { BASE_URL, TIME_OUT } from './request/config'  //导入全局的url和timeout
import LocalCache from '@/utils/cache'
const baseRequest = new BaseRequest({
    baseURL: BASE_URL,
    timeout: TIME_OUT,
    interceptors:{
        requestInterceptor: (config:any)=>{
            const token = LocalCache.getCache('token')
            if(token){
                config.headers.Authorization = `Bearer ${token}`
            }
            return config
        },
        requestInterceptorCatch:(error)=>{
            return error
        },
        responseInterceptor:(res)=>{
            return res.data
        },
        responseInterceptorCatch:(error)=>{
            return error
        }
    }
})

export default baseRequest
