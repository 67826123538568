import {Module} from 'vuex'
import { IConsultState } from './types'
import { IRootState } from '../types'
import { getList } from '@/service/consult/consult'
import moment from 'moment'
const customerModule: Module<IConsultState, IRootState> = {
    namespaced: true,
    state(){
        return{
            list:[],
            count:""
        }
    },
    mutations: {
        changList(state, data) {
            state.list = data.data
            state.count = data.count
        }
    },
    actions:{
        async getList({ commit }, payLoad: any) {
            const result: any = await getList('customer/list', payLoad)
            console.log(result)
            commit('changList', result)
        }
    }
}

export default customerModule
