/**
 *  @author 付天博
 *  @time 2021/11/24
 *  @desc 网络请求封装
 */
import axios from 'axios'  //导入axios
/**
 *   AxiosInstance,axios已经提供好的对实例的类型检测
 *   AxiosRequestConfig,axios已经提供好的对config(配置参数)的类型检测
 */
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import {RequestInterceptors, RequestConfig} from './type'
import { ElLoading  } from 'element-plus'
class BaseRequest {
    instance: AxiosInstance
    interceptors?: RequestInterceptors
    showLoading:boolean
    loading?: any
    constructor(config: RequestConfig){
        this.instance = axios.create(config)
        this.showLoading = config.showLoading ?? false
        this.interceptors = config.interceptors
        this.instance.interceptors.request.use(
            this.interceptors?.requestInterceptor,
            this.interceptors?.requestInterceptorCatch
        )
        this.instance.interceptors.response.use(
            this.interceptors?.responseInterceptor,
            this.interceptors?.responseInterceptorCatch
        )
        this.instance.interceptors.request.use((config)=>{
            if (this.showLoading){
                this.loading = ElLoading.service({
                    lock: true,
                    text: "加载中...",
                    background: 'rgba(0,0,0,0.6)'
                })
            }
            return config
        },(err)=>{
            return err
        })
        this.instance.interceptors.response.use((res)=>{
            setTimeout(() => {
                this.loading?.close()
            }, 500);
            return res
        },(error)=>{
            setTimeout(() => {
                this.loading?.close()
            }, 500);
            return error
        })
    }
    request<T>(config:RequestConfig):Promise<T> {
        return new Promise((resolve,reject)=>{
            if (config.showLoading) {
                this.showLoading = config.showLoading
            }
            if (config.interceptors?.requestInterceptor) {
                config = config.interceptors.requestInterceptor(config)
            }
            this.instance.request<any,T>(config).then(res => {
                if (config.interceptors?.responseInterceptor) {
                    res = config.interceptors.responseInterceptor(res)
                }
                this.showLoading = false
                resolve(res)
            }).catch(err => {
                this.showLoading = false
                reject(err)
            })
        })
    }

    get<T>(config: RequestConfig): Promise<T> {
        return this.request<T>({...config,method:"GET"})
    }

    post<T>(config: RequestConfig): Promise<T> {
        return this.request<T>({ ...config, method: "POST" })
    }
}

export default BaseRequest
