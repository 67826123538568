import {Module} from 'vuex'
import { INewsState } from './types'
import { IRootState } from '../types'
import { SearchList } from '@/service/news/news-request'
import message from '@/utils/message'
const newsModule: Module<INewsState, IRootState> = {
    namespaced: true,
    state() {
        return {
            list: [],
            count:0,
            searchTitle:""
        }
    },
    mutations: {
        changList(state,data){
            state.list = data.result
            state.count = data.count
        },
        changeSearchTitle(state, data){
            state.searchTitle = data
        },
        changeCleanSearchTitle(state){
            state.searchTitle = ""
        }
    },
    actions: {
        async getNewsList({commit},payLoad:any){
            const result:any = await SearchList('news/list', payLoad)
            console.log(result)
            commit('changList', result.data)
        }
    }
}
export default newsModule

