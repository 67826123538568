
import { defineComponent } from "vue";
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default defineComponent({
    name: "App",
    components: {
        ElConfigProvider,
    },
    setup() {
        return {
            locale: zhCn,
        }
     },
});
