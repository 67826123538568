// 开发 development
// 生产 production
// 测试 test

let BASE_URL = ''
const TIME_OUT = 10000

if (process.env.NODE_ENV === 'development') {
    BASE_URL = 'https://admin.www.1qit.com/'
} else if (process.env.NODE_ENV === 'production') {
    BASE_URL = 'https://admin.www.1qit.com/'
} else {
    BASE_URL = 'http://coderwhy.org/test'
}

export { BASE_URL, TIME_OUT }
