import baseRequest from '../index'

export function getList(url: string, queryInfo: any) {
    return baseRequest.post({
        url: url,
        data: queryInfo,
        showLoading:true
    })
}

