import {Module} from 'vuex'
import { IConsultState } from './types'
import { IRootState } from '../types'
import { getList } from '@/service/consult/consult'
import moment from 'moment'
const loginModule: Module<IConsultState, IRootState> = {
    namespaced: true,
    state(){
        return{
            list:[],
            count:""
        }
    },
    mutations: {
        changList(state, data) {
            state.list = data.result.map((item:any)=>{
                console.log(moment('2021-12-09T02:33:14.000Z').format("YYYY-MM-DD HH:mm"), 11)
                return{
                    id:item.id,
                    createAt: moment(item.createAt).format("YYYY-MM-DD HH:mm"),
                    name: item.name,
                    phone: item.phone,
                    item: item.item,
                    consule_desc: item.consule_desc
                }
            })
            state.count = data.count
        }
    },
    actions:{
        async getList({ commit }, payLoad: any) {
            const result: any = await getList('consult/list', payLoad)
            commit('changList', result.data)
        }
    }
}

export default loginModule
